.Levels {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__wrap {
    padding: 24px 8px !important;
    height: 100%;
    border-right: none;
  }

  &__nav {
    gap: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &:first-child {
      margin-top: 25px;
    }

    .MuiButtonBase-root {
      text-align: center;
    }
  }

  &__lvls {
    background: var(--grey-light) !important;
    border-radius: var(--common-border-radius) !important;
    padding: 8px;
    max-height: 48px;
    position: relative;
    width: 8rem;
  }
}
