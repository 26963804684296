:root {
  --bg-default: #1A1A1A;
  --bg-primary: #353859;
  --bg-secondary: #57A992;

  --bg-primary-hovered: #5D2DF5;

  --bg-hovered: rgba(128, 128, 128, 0.16);
  --bg-selected: rgba(144, 202, 249, 0.16);

  --white: #f1f1f1;
  --grey: #969696;

  --bg-primary-light: #4068E7;
  --grey-light: rgba(168, 168, 168, 0.25);

  --box-shadow-light: rgba(212, 216, 222, 0.2);

  --typo-primary: #FFFFFF;

  --disabled-color: #C5C5C5;

  --common-border-radius: 4px;
}

html{
  background-color: var(--bg-default);
}

.App {
  text-align: center;
}

.MuiList-root{
  padding-bottom: 0 !important;
}

  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.closeicon {
  position: absolute !important;
  top: 1rem !important;
  right: 1rem;
  z-index: 9999999;
  fill: white;
  cursor: pointer;
}

.MuiPaper-root{
  @media (max-width: 768px) {
    width: 100%;
    overflow: hidden;
   }
}

/* *{
  outline: 4px solid green !important;
}
 */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #424242;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #686868;
  border-radius: 6px;
}

