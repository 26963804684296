.Loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  z-index: 99;
  justify-content: center;
  backdrop-filter: blur(1px);

  &_visible {
    display: flex;
  }

  &__dots {
    z-index: 99;
    width: 40px;
    height: 10px;
    display: flex;
    justify-content: space-between;
  }

  &__dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    color: var(--bg-primary);
    left: 15px;
    animation: loaderSecondDot 1s infinite linear;

    &:first-child {
      left: 0;
      animation: loaderFirstDot 1s infinite linear;
    }

    &:last-child {
      left: 30px;
      animation: loaderThirdDot 1s infinite linear;
    }
  }
}

@keyframes loaderFirstDot {
  0% {
    transform: scale(0.7);
  }
  25% {
    transform: scale(0.97);
  }
  50% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.7);
  }
}

@keyframes loaderSecondDot {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(0.97);
  }
  75% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loaderThirdDot {
  0% {
    transform: scale(1.3);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.7);
  }
  75% {
    transform: scale(0.97);
  }
  100% {
    transform: scale(1.3);
  }
}
