.ScenePage2 {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;

  background-image: url('../../assets/bg2.jpg');
  background-size: cover;

  &.showBlur{
    filter: blur(4px);
  }

  .error{
    //text-align: center;
    color: red;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }

  

  .tooltips{
    border: none;
    background: none;
    cursor: pointer;
    display: block;
    position: relative;
    color: white;
    padding: 0;
    padding-bottom: 3px;
    font-size: 14px;
    &:before,&:after{
      content: '';
      position: absolute; top: 100%;
      width: 10%;
      height: 3px;
      background: rgb(236, 56, 24);
      transition: width .3s ease-in-out;
  }
  &:after{
   right: 50%;
}
&:before{
  left: 50%;
}

&:hover::before, &:hover::after{
  width: 50%;
}
  }

  .keyMscle {
    margin-left: auto;
    background-color: rgb(216, 213, 213);
    width: fit-content;
    padding: 2vw;
    z-index: 99;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    /*  align-items: center; */
    text-align: center;
    max-width: 450px;
    overflow: auto;
  }

  .modal {
    background-color: rgb(66, 65, 65);
    width: 30%;
    text-align: center;
    height: 60vh;
    z-index: 9999999;
    position: relative;
  }

  .settings {
    background-color: #4E4E4E;
    width: fit-content;
    text-align: center;
    padding: 1vw;
    z-index: 99;
    position: relative;
    display: flex;
    justify-content: center;
    /*  align-items: center; */
    text-align: center;
    max-width: 300px;
    overflow: auto;
    display: flex;
    flex-direction: column;

    input {
      width: 15px;
      height: 15px;
      margin-top: auto;
      margin-left: 10px;
      margin-bottom: auto;
      cursor: pointer;
    }

    .checkboxWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //padding-left: 1rem;
      //padding-right: 1rem;
      margin-bottom: 10px;
      transition: scale .3s ease-in-out;
      cursor: pointer;

      &:hover{
        scale: 1.1;
      }
    }

    .btnPatient {
      //margin-top: 10px;
      margin-bottom: 10px;
      transition: scale .3s ease-in-out;
    &:hover{
      scale: 1.1;
    }
      .MuiButtonBase-root {
        text-transform: none !important;
        //color: white;
        background-image: linear-gradient(
    to right,
    #54b3d6,
    #54b3d6 50%,
    #000 50%
  );
        background-size: 200% 100%;
        background-position: -100%;
        display: inline-block;
        padding: 5px 0;
        position: relative;
        background-clip: text;
        border-bottom: 1px solid white;
        border-radius: 0;
        transition: all 0.3s ease-in-out;

        &:before {
          content: '';
          background: #CA182E;
          
          display: block;
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 0;
          height: 3px;
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          color: #CA182E;
          background-position: 0;
        }

        &:hover::before {
          width: 100%;
        }
      }
    }
  }

  .ModalLeftHand{
    position: absolute;
    z-index: 999;
    width: 40rem;
    //height: 40rem;
    top: 16%;
    left: 2%;
    background-color: #4E4E4E;
    padding: 1rem;
    border-radius: 10px;
  }

  .ModalRightHand{
    position: absolute;
    z-index: 999;
    width: 40rem;
    //height: 40rem;
    top: 16%;
    right: 2%;
    background-color: #4E4E4E;
    padding: 1rem;
    border-radius: 10px;
  }

  .TestBtn {
    position: absolute;
    z-index: 99;
    cursor: pointer;
    letter-spacing: 1px;
    font-size: larger;
    text-align: center;
    width: 21rem;
    height: 5rem; /*  Высота границы */
    border-top: 8px solid white; /* Толщина границы */
    border-radius: 50% / 50% 50% 50% 50%; /* Верхний полукруг */
    background-color: transparent; /* Прозрачный фон */
    transition: scale 0.3s ease-in-out, opacity 0.2s ease-in-out;
    opacity: 0.4;

    @media (min-width: 2000px) {
      width: 29rem;
      height: 10rem;
    }

    svg {
      fill: white;
      transform: rotate(-90deg);
      margin-top: -6rem;
      //margin-left: -55px;
    }

    &:hover {
      scale: 1.4;
      opacity: 1;
      span {
        opacity: 1; /* Показать текст при наведении */
      }
    }

    span {
      position: absolute;
      margin-left: -50px;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    &.right {
      top: 35%;
      right: 2%;
      transform: rotate(90deg);
      @media (min-width: 2000px) {
        top: 35%;
        right: 2%;
      }
    }
    &.left {
      top: 35%;
      left: 2%;
      transform: rotate(-90deg);
    }
    &.top {
      top: 6%;
      left: 40%;
      //transform: rotate(-90deg);
    }
    &.bot {
      bottom: 25%;
      left: 40%;
      transform: rotate(180deg);

      span {
        transform: rotate(180deg);
      }
    }
  }
}

.showBlur{
  filter: blur(4px);
}

.popUpGestures,.popUpGesturesBtn{
  position: absolute;
  top: 12rem;
  right: 0;
  background-color: #CA182E;
  border-radius: 25px 0 0 25px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
  width:5vw;
  overflow: hidden;
  z-index: 1;
  transition: width 0.3s ease-in-out, scale 0.3s ease-in-out, background-color 0.3s ease-in, color 0.3s ease-in-out;

  &-link{
    top: 18rem;
    z-index: 1;
    position: absolute;
  right: 0;
  background-color: #CA182E;
  border-radius: 25px 0 0 25px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
  width: max-content;
  padding-left: 1rem;
  overflow: hidden;
  transition: width 0.3s ease-in-out, scale 0.3s ease-in-out, background-color 0.3s ease-in, color 0.3s ease-in-out;
  svg{
    width: 24px;
    height: 24px;
    transform: rotate(-180deg); 
    transition: scale 0.3s ease-in-out;
    margin-right: auto;
  }

  span{
    color: white;
    
    text-decoration: none;
    letter-spacing: 0.5px;
  }

  &:hover{
    background-color: white;
    width: calc-size(calc-size(max-content, size), size + 40px);
    span{
      color: #CA182E !important;
    }
    svg{
      fill: #CA182E;
    }
  }
  }

  .MuiButtonBase-root{
    margin: 0 !important;
    justify-content: end;
    width: 100%;
    text-transform: none;
    font-size: 16px !important;
    letter-spacing: 1px;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in, color 0.3s ease-in-out !important;

    color: white !important;

  }
  svg{
    width: 24px;
    height: 24px;
    transform: rotate(-180deg); 
    transition: scale 0.3s ease-in-out;
    margin-right: auto;
  }

  &:hover{
    background-color: white;
    color: #CA182E !important;
    width: 6vw;
  }
}

.dashboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0.5px;
  gap: 25px;
  padding: 1vw 2vw;
  padding-top: 0;
  max-height: 250px;
  overflow: auto;
  background-color: rgb(216, 216, 216);
  color: #4E4E4E;
  border-bottom: 1px solid #4E4E4E;
  padding-top: 0.5rem;

  &__header{
    background-color: rgb(255, 255, 255);
    padding: 1vw;
    height: 100%;
    border-radius: 25px;
    margin-top: auto;
    //border-bottom: 1px solid #4E4E4E;
    //padding-top: 0.5vw;
    height: 100%;
  }

  .logoWrapper{
    display: block;
    position: relative;
    text-decoration: none;
    color: #000;
    &:before,&:after{
      content: '';
      position: absolute; top: 100%;
      width: 0%;
      height: 3px;
      background: rgb(236, 56, 24);
      transition: width .3s ease-in-out;
  }
  &:after{
   right: 50%;
}
&:before{
  left: 50%;
}

&:hover::before, &:hover::after{
  width: 50%;
}

  }

  .logo-text{
    display: inline;
    font-size: 46px;
    letter-spacing: 2px;
    margin-left: 20px;
    margin-bottom: 0;
  }

  svg{
    fill: #BA051B;
    width: 200px;

  }

  &__header-button {
    width: 120px;
    text-transform: none !important;
    font-size: 16px !important;
    padding: 10px 10px !important;
    border-radius: 20px !important;
    margin-right: 5px !important;
    border: 1px solid #4E4E4E !important;
    color: #4E4E4E !important;
    background-color: transparent !important;
    transition: background-color .4s ease-in-out, color .4s ease-in-out !important;

    &:hover {
      background-color: #BA051B !important;
      color: white !important;
      border: 1px solid #BA051B !important;
    }
  }
  .lvls-btn-wrapper{
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 5px;
    width: 12rem;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    padding: 1vw;
    border-radius: 25px;
    padding-top: 0;
    //margin-top: auto;
    height: 100%;
    //border-bottom: 1px solid #4E4E4E;
  }
  .lvls-btn{
    border-radius: 40px;
    min-width: auto !important;
    border: 1px solid #4E4E4E !important;
    color: #4E4E4E !important;
    background-color: transparent !important;
    transition: background-color .4s ease-in-out, color .4s ease-in-out !important;

    &:hover {
      background-color: #BA051B !important;
      color: white !important;
      border: 1px solid #BA051B !important;
    }
  }

  &_date{
    margin-bottom: 1rem;
  }

  span {
    text-align: center;
    width: 100%;
    display: block;
    
    font-size: 20px;
    font-weight: bold;
  }

  .keyMscle{
    color: #4E4E4E;
    font-size: 18px;
    letter-spacing: normal;
    line-height: 24px;
    font-weight: 600;
    padding: 2rem;
    border-radius: 25px;
    //border: 1px solid #4E4E4E;
    //margin-top: auto;
    background-color: rgb(255, 255, 255);
    margin-top: auto;
    height: 100%;

    p{
      display: inline;
      border-bottom: 1px solid #BA051B;
      font-weight: 800;
    }
  }
}

.clear {
  position: relative !important;
  margin-left: auto !important;
  margin-right: 0 !important;
  width: fit-content !important;
  display: flex !important;
}
