.Footer {
  background-color: var(--bg-primary) !important;
  padding: 1vw;
  height: 70px;
  text-align: center;
  width: 100%;
  z-index: 100;
  }


