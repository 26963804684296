.ActiveMusclesPieChart {
  height: 100%;
  width: 100%;
  min-height: 40vh;

  * {
    &:focus {
      outline: none;
    }
  }
}