.Levels {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__wrap {
    padding: 24px 8px !important;
    border-right: 2px solid var(--bg-primary);
    height: 100%;
  }

  &__nav {
    gap: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &:first-child {
      margin-top: 25px;
    }

    .MuiButtonBase-root {
      text-align: center;
    }
  }

  &__lvls {
    background: var(--grey-light) !important;
    border-radius: var(--common-border-radius) !important;
    padding: 8px;
    max-height: 48px;
    position: relative;
    width: 5rem;

    &.Mui-selected {
      &::before {
        content: ' ';
        background: var(--bg-primary);
        width: 12px;
        height: 100%;
        border-top-right-radius: var(--common-border-radius);
        border-bottom-right-radius: var(--common-border-radius);
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}
