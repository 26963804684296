.BoxPlotChart {
  width: 100%;
  min-height: 50vh;

  text{
    text-anchor: middle;
  }
  &__item {
    background-color: #353859;
    border-radius: 10px;
    height: 300px;
    min-width: 350px;
    @media (min-width: 800px) {
      height: 500px;
    }
  }
  &__item1 {
    background-color: #353859;
    border-radius: 10px;
    height: 280px;

    @media (min-width: 800px) {
      height: 300px;
    }
  }

  @media (min-width: 1200px) {
    height: 600px;

    height: 100%;
    display: flex;
    gap: 10px;
  }
  .recharts-wrapper.pie {
    margin: auto;
    margin-bottom: 5vw;
    text-align: center;
    flex-direction: column;
    
    .recharts-legend-wrapper {
      position: relative !important;
      top: 20px;
      left: 40px;
      width: auto !important;
    }
  }

  .description {
    text-align: center;
   
    @media (max-width: 1200px) {
      font-size: 11px !important;
    }

    .dscr{
      @media (max-width: 1200px) {
       padding-top: 80px;
      }
    }
  }

  .recharts-wrapper{
    @media (max-width: 500px) {
      max-width: 350px !important;
    }
  }

}