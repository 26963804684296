.Modal {
  width: 100vw;
  height: 100vh;
  
  background-color: var(--bg-default);
  color: var(--typo-primary);

  &__pageContent {
    display: flex;
    gap: 20px;
    height: calc(100% - 70px);
  }

  &__patientsList {
    width: 200px;
    padding: 24px 8px !important;
    border-right: 2px solid var(--bg-primary);
  }

  &__DatesList {
    width: 200px;
    text-align: center;
    font-size: 1.2rem;
  }

  &__AttemptList {
    width: 200px;
    text-align: center;
    font-size: 1.2rem;
  }

  &__Levels {
    width: 300px;
    text-align: center;
    font-size: 1.2rem;
  }

  &__userContent {
    width: calc(100% - 300px);
  }
}