
.select_container {
    padding: 20px;
    background-color: grey;
};
.select_button {
    margin: 10px;
    padding: 16px 42px;
    border-radius: 3px;
    box-shadow: 0px 0px 12px -2px rgba(0,0,0,0.5);
    line-height: 1.25;
    background: white;
    text-decoration: none;
    color: grey;
    font-size: 16px;
    letter-spacing: .08em;
    text-transform: uppercase;
    position: relative;
    transition: background-color .6s ease;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 50%;
      left: 50%;
      top: var(--mouse-y);
      left: var(--mouse-x);
      transform-style: flat;
      transform: translate3d(-50%,-50%,0);
      background: rgba(white,.1);
      border-radius: 100%;
      transition: width .3s ease, height .3s ease;
      border-radius: 20px;
    }
    &:focus,
    &:hover {
        background: darken(white,7%);
    }
    &:active {
      &:after {
        width: 300px;
        height: 300px;
      }
    }
  }
  .select_button-container {
    max-width: 700px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 10px;
  }

  .select_button-user{
    display: inline-flex;
    height: 40px;
    width: 150px;
    border: 2px solid #BFC0C0;
    margin: 0;
    color: #BFC0C0;
    text-transform: uppercase;
    text-decoration: none;
    font-size: .8em;
    letter-spacing: 1.5px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  

  
  .select_button-user {
    left: 0;
  }
  
  .select_button-user {
    color: #2D3142;
  }