.DashboardPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: 'Montserrat';
  

  .err{
    color: red;
    margin: auto;
    font-size: large;

  }

  &__header {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 1.2rem;
    width: 100%;
    height: auto;
    justify-content: space-between;

    @media (max-width: 500px) {
      flex-direction: column;
      margin-bottom: 25px;
      text-align: center;

      button{
        font-size: 0.8rem;
        padding: 8px;
      }
      
    }
  }

  &__tabs {
    .MuiTabs-indicator {
      background: var(--bg-primary-light) !important;
    }
  }

  &__tab {
    padding: 8px;
    cursor: pointer;
    color: var(--typo-primary) !important;
    font-family: 'Montserrat' !important;

    &.Mui-selected {
      color: var(--bg-primary-light) !important;
    }
  }

  &__buttons {
    gap: 10px;
    display: flex;
    margin-right: 15px;
    align-items: center;
  }

  &__button {
    padding: 8px;
    color: var(--typo-primary) !important;
    border: 1px solid var(--bg-primary-light) !important;
    font-family: 'Montserrat' !important;

    &[disabled] {
      opacity: 0.6;
    }
  }

  &__link{
    cursor: pointer;
    font-family: 'Montserrat' !important;
  }

  &__content {
    width: 100%;
    height: calc(100% - 120px);
    padding: 8px;
  }

  &__buttonWrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    span {
      font-size: xx-small;
      position: absolute;
      top: auto;
      bottom: -5px;
    }
  }

  animation: scroll 100s linear infinite;
  background: url("https://images.unsplash.com/photo-1465146633011-14f8e0781093?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3450&q=80"),
    #111111;
  color: #eee;
  height: 100vh;
  min-width: 360px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  perspective-origin: 50% 50%;
}

@keyframes scroll {
  100% {
    background-position: 0px -400%;
  }
}

/* Fallback if the operatring system prefers reduced motion*/
@media (prefers-reduced-motion) {
  .wrapper {
    animation: scroll 800s linear infinite;
  }
}

@media (min-width: 670px) {
  .title {
    font-size: 5rem;
  }

}