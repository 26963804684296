.PatientInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 10px;

  &__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 120px;
    width: 100%;
    border-bottom: 1px solid var(--bg-primary-light);
    align-items: center;
    padding-bottom: 4px;

    @media screen and (max-width: 768px){
      gap: 40px;
    }

    @media screen and (min-width: 769px){
      gap: 10px;
    }

    @media screen and (min-width: 1800px){
      gap: 40px;
    }
  }

  span {
    margin-left: auto;
    color: var(--bg-primary-light);
    word-break: break-all;
  }

  button {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    margin-top: 2vh;
    color: var(--typo-primary) !important;
    border: 1px solid var(--bg-primary-light) !important;
    text-transform: none;
    transition: background-color 0.3s ease-in-out;

    &:hover{
      background-color: var(--bg-primary-light);
    }
    
    svg {
      margin-left: 10px;
    }
  }
}
