.StatisticsComponent {
  height: 100%;

  &__container {
    width: 100%;;
    margin: 0;
  }

  &__item {
    width: 100%;
  }

  .graphics{
    height: auto;
  }
}