.MainPage {
  width: 100vw;
  height: 100vh;
  font-family: 'Montserrat';

  background-color: var(--bg-default);
  color: var(--typo-primary);

  &__header {
    height: 70px;
  }

  &__pageContent {
    display: flex;
    gap: 20px;
    /* height: calc(100% - 70px); */
    min-height: 100vh;
  }

  &__patientsList {
    width: 300px;
    padding: 24px 8px;
    border-right: 2px solid var(--bg-primary);
    padding-right: 16px;
  }

  &__DatesList {
    width: 300px;
    text-align: center;
    font-size: 1.2rem;
  }

  &__AttemptList {
    width: 300px;
    text-align: center;
    font-size: 1.2rem;
  }

  &__Levels {
    width: 300px;
    text-align: center;
    font-size: 1.2rem;
  }

  &__userContent {
    width: calc(100% - 300px);
  }
}
