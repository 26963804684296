.Gestures {
  
  min-height: 100vh;
  
  background-color: var(--bg-default);
  color: var(--typo-primary);

  &__pageContent {
    display: flex;
    gap: 20px;
    height: calc(100% - 70px);
  }

  &__patientsList {
    width: 200px;
    padding: 24px 8px !important;
    border-right: 2px solid var(--bg-primary);
  }

  &__DatesList {
    width: 200px;
    text-align: center;
    font-size: 1.2rem;
  }

  &__AttemptList {
    width: 200px;
    text-align: center;
    font-size: 1.2rem;
  }

  &__Levels {
    width: 300px;
    text-align: center;
    font-size: 1.2rem;
  }

  &__userContent {
    width: calc(100% - 300px);
  }

  &__buttons {
    gap: 10px;
    display: flex;
    margin-right: 15px;
    align-items: center;
  }

  &__button {
    padding: 8px;
    color: var(--typo-primary) !important;
    border: 1px solid var(--bg-primary-light) !important;
    font-family: 'Montserrat' !important;

    &[disabled] {
      opacity: 0.6;
    }
  }
  &__buttonWrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    span {
      font-size: xx-small;
      position: absolute;
      top: auto;
      bottom: -5px;
    }
  }
}