.RootLayout {
  position: relative;
  width: 100%;
  //height: calc(100vh + 140px);
  background-color: var(--bg-default);
  color: var(--typo-primary);

  .scene-pageWrapper{
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
}
