.MainPageHeader {
  background-color: var(--bg-primary) !important;
  height: 70px;
  font-family: 'Montserrat';

  .ver{
    font-size: smaller;
    font-style: italic;
    margin-left: 15px; 
  }

  .profile{
    display: flex;
    align-items: center;
  }

  .logo{
    margin-right: 1vw;
    width: 80px;
    height: 65px;
    cursor: pointer;
  }

  span{
    font-size: larger;
    font-weight: 600;
  }
}