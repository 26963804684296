.HandModal {
  .hm-tabs {
    display: flex;
    justify-content: space-between;
  }

  .closeBtn {
    display: flex;
    justify-content: space-between;

    .closeBtnText {
      cursor: pointer;
      transition:
        0.3s ease-in-out color,
        0.3s ease-in-out scale;

      &:hover {
        color: #ca182e;
        scale: 1.2;
      }
    }
  }

  .line {
    &::after {
      content: '';
      position: relative;
      width: 100%;
      height: 2px;
      background-color: white;
      display: block;
      margin-top: 1rem;
    }
  }

  .hm-tab {
    height: fit-content;
    cursor: pointer;
    min-width: 150px;
    padding: 0.5rem;
    padding-top: 0;
    transition:
      color 0.3s ease-in-out,
      scale 0.3s ease-in-out;
    display: block;
    position: relative;

    &-content {
      text-align: center;
      align-items: center;
      font-size: 2.0rem;
      &.title {
        color: white;
      }
      &.energy {
        text-align: center;
        color: #ca182e;
        padding-bottom: 1px;
        border-bottom: 1px solid #ca182e;
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      width: 10%;
      height: 3px;
      background: rgb(236, 56, 24);
      transition: width 0.3s ease-in-out;
    }
    &:after {
      right: 50%;
    }
    &:before {
      left: 50%;
    }

    &:hover::before,
    &:hover::after {
      width: 50%;
    }

    &:hover {
      scale: 1.05;
      border-color: #ca182e;
    }
  }

  .hm-content {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .miniTitle {
    border-bottom: white solid 3px;
    padding-bottom: 0.1rem;
    margin-right: 1rem;
    font-weight: bold;
  }

  .titleModal {
    display: flex;
    
    font-size: 1.2rem;
    margin-top: 1rem;

    .titleModal-column {
      display: flex;
      flex-direction: column;
      border-bottom: white solid 2px;
      padding-bottom: 0.1rem;
      margin-left: 5px;
      overflow: hidden;

      span {
        width: fit-content;
      }

      
    }
  }
  .paramsWrap{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
  }
}
