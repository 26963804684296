.DataImport {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  background-color: var(--bg-secondary);
  border-radius: var(--common-border-radius);

  &__text {
    pointer-events: none;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--typo-primary);

    animation: 3s linear sizeAnimation 0s infinite;
  }
}

@keyframes sizeAnimation {
  0% {
    transform: translate(-50%, -50%) scale(1.0);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.25);
  }

  100% {
    transform: translate(-50%, -50%) scale(1.0);
  }
}