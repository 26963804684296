.helpTooltip {
  &.step1 {
    border-radius: 25px;
    width: 25vw;
    background-color: white;
    color: black;
    padding: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: absolute;

    z-index: 9999999;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    top: 15vh;
    left: 55vw;
  }

  &.step2 {
    border-radius: 25px;
     width: 25vw;
    background-color: white;
    color: black;
    padding: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: absolute;

    z-index: 9999999;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    top: 25vh;
    left: 55vw;
  }
  &.step3 {
    border-radius: 25px;
     width: 25vw;
    background-color: white;
    color: black;
    padding: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: absolute;

    z-index: 9999999;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    top: 25vh;
    left: 15vw;
  }
  &.step4 {
    border-radius: 25px;
     width: 25vw;
    background-color: white;
    color: black;
    padding: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: absolute;
    z-index: 9999999;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    top: 35vh;
    left: 55vw;
  }
  &.step5 {
    border-radius: 25px;
     width: 25vw;
    background-color: white;
    color: black;
    padding: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: absolute;
    z-index: 9999999;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    top: 35vh;
    left: 15vw;
  }
  &__video{
    margin-top: 1rem;
    border-radius: 25px;
    margin-bottom: 0.5rem;
  }
  &__btn{
    padding: 0.6rem 1.2rem;
    border-radius: 25px;
    border: 1px solid rgb(204, 184, 184);
    cursor: pointer;
    color: white;
    background-color: transparent;
    color: black;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.5px;
    transition: 0.3s ease-in-out background-color, 0.3s ease-in-out color;

    &:hover{
      color: white;
      background-color: rgb(13, 230, 13);
    }
  }
}
