.background-container {
	height: 100%;
}
form{
	margin-top: auto;
	background: #fff!important;
  min-height:80vh;
  position: relative;
}
.table-title {
   	width: 100%;
    padding: 10px;
    color: black;
	font-size: larger;
	border-bottom: 1px solid rgb(194, 185, 185,0.7);
	justify-content: center;
	display: flex;
}
.table {
	margin-bottom: 25px;
    color: white;
    height: 100%;
}

.delete{
    margin: 0 auto;
    color: #eee;
    padding: 7px 13px;
    border: none;
    border-radius: 5px;
    background: #2c3e50;
	cursor: pointer;
  }
  .delete:hover{
    background: #c0392b;
  }
  .delete:active{
    background: #e74c3c;
  }

button {
	justify-self: center;
	align-self: center;
  margin-top: auto;
}