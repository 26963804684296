.JointLineChart {
  width: 100%;
  height: 100%;
  min-height: 40vh;

  .close{
    position: absolute;
    top: -2rem;
    right: 0.2rem;
    z-index: 999;
    cursor: pointer;
    background-color: var(--bg-default);
    color: white;
    /* border-radius: 25px; */
    padding: 0.4rem;
  }
}