.PanelWrapper {
  height: 100%;
  width: 100%;
  padding: 8px;
  border-radius: var(--common-border-radius);
  box-shadow: -1px -1px 4px 2px var(--box-shadow-light) !important;

  &.test{
    left: 0;
    position: fixed;
    height: auto;
    background: var(--bg-default);
  }

  &.base{
    bottom: 0px;
    height: auto;
    background: var(--bg-default);
    width: 100%;
  }
}