.GestureTable {
  &__table {
    height: 100%;
    background: var(--bg-default) !important;
    box-shadow: none !important;

    td,
    th {
      background: var(--bg-default) !important;
      color: var(--typo-primary) !important;
      border-color: var(--grey) !important;
    }

    tr:last-child td {
      border: none !important;
    }
  }

  &__row {
    cursor: pointer;
    &[data-tooltip] {
      position: relative; /* Относительное позиционирование */ 
     }
     &[data-tooltip]::after {
      content: attr(data-tooltip); /* Выводим текст */
      position: absolute; /* Абсолютное позиционирование */
      left: 0; top: 0; /* Положение подсказки */
      background: #3989c9; /* Синий цвет фона */
      color: #fff; /* Цвет текста */
      padding: 0.5em; /* Поля вокруг текста */
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Параметры тени */
      pointer-events: none; /* Подсказка */
      opacity: 0; /* Подсказка невидима */
      transition: 1s; /* Время появления подсказки */
      border-radius: 8px;
     } 
     &[data-tooltip]:hover::after {
      opacity: 1; /* Показываем подсказку */
      top: 0; /* Положение подсказки */
      left: 250px;
     }

    &:hover td {
      background: var(--bg-hovered) !important;
    }

    &_selected {
      td {
        background: var(--bg-selected) !important;
      }

      &:hover td {
        background: var(--bg-selected) !important;
      }
    }
  }

  &__gradient {
    margin: 0 auto;
    display: flex;
    gap: 2px;
    height: 30px;
    background: var(--grey-light);
  }

  &__gradientStep {
    width: 4px;
    height: 100%;
  }

  &__dot {
    margin: 0 auto;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
}
