.ProfilePage {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: var(--bg-default);
  color: var(--typo-primary);
  

  &__container {
    padding: 8vw;
    justify-content: space-between;
    box-sizing: border-box !important;
    margin-left: 0 !important;
    width: 100% !important;
    flex-direction: column !important;

    @media screen and (min-width: 769px){
      flex-direction: row !important;
    }
  }

  .MuiGrid-root{
    max-width: 100%;
  }

  &__item2{
    box-shadow: -1px -1px 4px 2px var(--box-shadow-light) !important;
    padding: 1vw;
  }

  .PatientsList__searchInput{
    margin: auto;
    display: flex;
    margin-top: 25px;
    width: fit-content;
  }

  .MuiFormControl-root{
    margin: auto;
    display: flex;
    margin-top: 25px;
    border: 1px solid white;
    border-radius: 8px;
  }

  .PanelWrapper{
   /*  max-height: 25vw; */
    overflow: auto;
    margin-bottom: 25px;

    @media screen and (min-width: 769px){
     /*  max-height: 25vw; */
    }
  }

  &__btn{
    width: fit-content;
    margin: auto !important;
    margin-top: 25px !important;
    display: block !important;
    background-color: var(--bg-primary) !important;

    &:hover{
      background-color: var(--bg-primary-hovered) !important;
    }
  }

  .recharts-wrapper{
    margin: auto;
    text-align: center;
    flex-direction: column;
  }

  .recharts-default-legend{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .recharts-legend-wrapper{
    position: relative !important;
    margin-top: 1vw;
  }

  .description{
    text-align: center;
    margin-top: 4vw;
  }
}